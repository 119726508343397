
import { FacilityV2EntityModel, LinkageFacilityQueryModel } from '@common-src/entity-model/facility-v2-entity';
import { LinkageDeviceEntityModel, LinkageDeviceQueryModel } from '@common-src/entity-model/linkage-device-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${ALARM_CENTER_BASE_REQUEST_PATH}/linkage`;

class LinkageDeviceService implements ICRUDQ<LinkageDeviceEntityModel, LinkageDeviceQueryModel> {
    async create(model: LinkageDeviceEntityModel):Promise<LinkageDeviceEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<LinkageDeviceEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new LinkageDeviceEntityModel().toModel(res);
    }

    async update(model: LinkageDeviceEntityModel):Promise<LinkageDeviceEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: LinkageDeviceEntityModel):Promise<LinkageDeviceEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: LinkageDeviceQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new LinkageDeviceEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new LinkageDeviceEntityModel().toModel(item));
    }

    async getLinkageDevice(query?: LinkageFacilityQueryModel, page?: number, limit?: number):Promise<any> {
        // const deviceSearchDto = query?.toService();
        // const params: any = Object.assign({ page, limit }, { params: {
        //     deviceSearchDto: _.get(deviceSearchDto, 'params'),
        //     groupId: _.get(deviceSearchDto, 'groupId'),
        //     linkageId: _.get(deviceSearchDto, 'linkageId')
        // } });
        // delete params.params.deviceSearchDto.groupIds;
        const params = Object.assign({ page, limit }, query?.toService());
        const url = `${BI_BASE_REQUEST_PATH}/linkage/listFacility`;
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new FacilityV2EntityModel().toModel(item));
        return res;
    }
}

export default new LinkageDeviceService();
