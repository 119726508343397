import { Component } from 'vue-property-decorator';
import { BaseComponent } from './base-component';
import { GroupEntityType } from '@common-src/model/enum';

@Component
export default class GroupComponent extends BaseComponent {
    GroupEntityType = GroupEntityType;
    get GroupId() {
        return this.$route.query.groupId as string;
    }
}
