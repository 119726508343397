import { BaseEntityModel } from '@common-src/model/base-model';
import { AttributeEntityType, GroupEntityType } from '@common-src/model/enum';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlLinkDeviceModel } from '@common-src/model/form-control';
import { GroupQueryModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import LinkageGroupService from '@common-src/service/linkage-group';

export class LinkageDeviceEntityModel extends BaseEntityModel {
    constructor(groupId?: string) {
        super();
        this.groupId = groupId;
    }

    @FormControl({
        label: '分组',
        type: FormControlType.TREE_SELECT,
        optionsPromise: LinkageGroupService.getGroupTree,
        optionsPromiseParam: GroupEntityType.LINKAGE,
        required: false
    } as FormControlOptionModel)
    groupId: string = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    linkageName: string = undefined;

    @FormControl({
        label: '关联设备',
        type: FormControlType.LINKAGE_DEVICE,
        linkageType: AttributeEntityType.DEVICE,
        required: true
    } as FormControlLinkDeviceModel)
    fromList: Array<{id: string, name: string}> = undefined;
    fromDeviceNames: string = undefined;

    @FormControl({
        label: '被关联设备',
        type: FormControlType.LINKAGE_DEVICE,
        linkageType: AttributeEntityType.DEVICE,
        required: true
    } as FormControlLinkDeviceModel)
    toList: Array<{id: string, name: string}> = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'linkageName'
            },
            {
                title: '关联设备',
                width: 550,
                dataIndex: 'fromDeviceNames'
            },
            // {
            //     title: '被关联设备',
            //     dataIndex: 'toList'
            // },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class LinkageDeviceQueryModel extends GroupQueryModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 12
    })
    name: string = undefined;

    toService() {
        const data: any = {
            params: {
                name: this.name,
                groupIds: this.GroupIds
            }
        };
        return data;
    }
}
